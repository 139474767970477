/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Datetime from 'vux/src/components/datetime';
import Group from 'vux/src/components/group';
export default {
  name: 'WeekCalendar',
  components: {
    Group: Group,
    Datetime: Datetime
  },
  props: {
    eventList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    timestamp: {
      //当前时间的时间戳
      type: [String, Number]
    },
    currDate: {
      //当前日期
      type: String
    }
  },
  data: function data() {
    return {
      monthData: [{
        title: '01月',
        value: '01'
      }, {
        title: '02月',
        value: '02'
      }, {
        title: '03月',
        value: '03'
      }, {
        title: '04月',
        value: '04'
      }, {
        title: '05月',
        value: '05'
      }, {
        title: '06月',
        value: '06'
      }, {
        title: '07月',
        value: '07'
      }, {
        title: '08月',
        value: '08'
      }, {
        title: '09月',
        value: '09'
      }, {
        title: '10月',
        value: '10'
      }, {
        title: '11月',
        value: '11'
      }, {
        title: '12月',
        value: '12'
      }],
      //月份数组
      weekData: [{
        title: '日',
        value: '0'
      }, {
        title: '一',
        value: '1'
      }, {
        title: '二',
        value: '2'
      }, {
        title: '三',
        value: '3'
      }, {
        title: '四',
        value: '4'
      }, {
        title: '五',
        value: '5'
      }, {
        title: '六',
        value: '6'
      }],
      //周数组
      weekList: [],
      //当前周数组
      prevWeekList: [],
      //上一周数组
      nextWeekList: [],
      //下一周数组
      index: 1,
      liW: 0,
      isToday: false,
      //是否今天
      isWeekday: false,
      //是否周几
      today: '',
      //今天
      weekday: '',
      //今天周几
      month: '',
      //当前几月
      year: '',
      //当前年份
      showMonth: false,
      //是否显示月份
      curDate: '',
      timetamp: 0,
      //当前时间的时间戳
      selectDat: '' //选择的年月

    };
  },
  computed: {},
  watch: {
    /**
     * @function监听当前日期的时间戳，并获取对应信息
     *      wzx
     *   2018.12.04
     **/
    timestamp: function timestamp(val) {
      this.timetamp = val;
      this.year = this.$utils.DateUtils.formatTimeStamp(val).substring(0, 4);
      this.month = this.$utils.DateUtils.formatTimeStamp(val).substring(5, 7);
      this.today = this.$utils.DateUtils.formatTimeStamp(val);
      this.weekday = this.$utils.DateUtils.getWeekTimeStamp(val);
      this.getWeekList(val);
    }
  },
  methods: {
    /**
     * @function点击年或月份，显示月份下拉菜单
     *      wzx
     *   2019.01.14
     **/
    changeDate: function changeDate(val) {
      this.year = val.substring(0, 4);
      this.month = val.substring(5, 7);
      var nowDate = this.year + '-' + this.month + '-' + '01';
      this.timetamp = new Date(nowDate).getTime(); //获取当前天的时间戳

      this.weekday = this.$utils.DateUtils.getWeekTimeStamp(this.timetamp); //定位当前天，增加dd的weekday样式

      this.getWeekList(this.timetamp);
      this.$emit('monthClick', nowDate);
    },

    /**
     * @function根据时间戳获取当前周日期数组、上一周日期数组、 下一周日期数组，并根据活动事件数组与周历对应
     *      wzx
     *   2018.12.04
     **/
    getWeekList: function getWeekList(value) {
      this.weekList = [];
      this.prevWeekList = [];
      this.nextWeekList = []; // let timestamp=new Date(value).getTime();

      var c = new Date(value).getDay(); //必须为value的日期
      // let a = this.$utils.DateUtils.formatTimeStamp(value);    //获取当前几号
      // let b = this.$utils.DateUtils.getWeekTimeStamp(value);   //获取当前周几（中文格式）
      //获取当前周一周的时间戳，并根据一周时间戳返回一周的日期

      for (var i = 0; i < 7; i++) {
        var curWeek = value + (i - c) * 24 * 3600 * 1000; //获取当前周几的时间戳

        var prevWeek = value + (i - c - 7) * 24 * 3600 * 1000; //获取上一周几的时间戳

        var nextWeek = value + (i - c + 7) * 24 * 3600 * 1000; //获取下一周几的时间戳

        this.weekList.push({
          day: this.$utils.DateUtils.formatTimeStamp(curWeek),
          week: this.$utils.DateUtils.getWeekTimeStamp(curWeek)
        });
        this.prevWeekList.push({
          day: this.$utils.DateUtils.formatTimeStamp(prevWeek),
          week: this.$utils.DateUtils.getWeekTimeStamp(prevWeek)
        });
        this.nextWeekList.push({
          day: this.$utils.DateUtils.formatTimeStamp(nextWeek),
          week: this.$utils.DateUtils.getWeekTimeStamp(nextWeek)
        });

        for (var j = 0; j < this.eventList.length; j++) {
          if (this.eventList[j] === this.weekList[i].day) {
            this.weekList[i].hasEvent = true;
          } else if (this.eventList[j] === this.prevWeekList[i].day) {
            this.prevWeekList[i].hasEvent = true;
          } else if (this.eventList[j] === this.nextWeekList[i].day) {
            this.nextWeekList[i].hasEvent = true;
          }
        }
      }
    },

    /**
     * @function点击某天
     *      wzx
     *   2018.12.04
     **/
    dayClick: function dayClick(value) {
      this.year = this.$utils.DateUtils.formatTimeStamp(value).substring(0, 4);
      this.month = this.$utils.DateUtils.formatTimeStamp(value).substring(5, 7);
      this.weekday = this.$utils.DateUtils.getWeekTimeStamp(value);
      this.timetamp = new Date(value).getTime(); //获取当前日期的时间戳，不是今天的时间戳

      this.$emit('dayClick', value);
    },

    /**
     * @function周历左滑功能，显示下一周数据
     *      wzx
     *   2018.12.04
     **/
    onSwipeLeft: function onSwipeLeft() {
      var _this = this;

      this.index = 2;
      var container = this.$refs.container;
      container.style.transform = 'translateX(' + -this.index * this.liW + 'px)';
      container.style.webkitTransform = 'translateX(' + -this.index * this.liW + 'px)';
      container.style.transition = 'all 0.2s';
      container.style.webkitTransition = 'all 0.2s'; //兼容

      this.timetamp = this.timetamp + 24 * 3600 * 1000 * 7;
      this.getWeekList(this.timetamp);
      this.year = this.$utils.DateUtils.formatTimeStamp(this.timetamp).substring(0, 4);
      this.month = this.$utils.DateUtils.formatTimeStamp(this.timetamp).substring(5, 7);
      setTimeout(function () {
        _this.index = 1;
        container.style.transform = 'translateX(' + -_this.index * _this.liW + 'px)';
        container.style.webkitTransform = 'translateX(' + -_this.index * _this.liW + 'px)';
        container.style.transition = 'all 0s';
        container.style.webkitTransition = 'all 0s'; //兼容
      }, 200);
    },

    /**
     * @function周历右滑功能，显示上一周数据
     *      wzx
     *   2018.12.04
     **/
    onSwipeRight: function onSwipeRight() {
      var _this2 = this;

      this.index = 0;
      var container = this.$refs.container;
      container.style.transform = 'translateX(' + this.index * this.liW + 'px)';
      container.style.webkitTransform = 'translateX(' + this.index * this.liW + 'px)';
      container.style.transition = 'all 0.2s';
      container.style.webkitTransition = 'all 0.2s'; //兼容

      this.timetamp = this.timetamp - 24 * 3600 * 1000 * 7;
      this.getWeekList(this.timetamp);
      this.year = this.$utils.DateUtils.formatTimeStamp(this.timetamp).substring(0, 4);
      this.month = this.$utils.DateUtils.formatTimeStamp(this.timetamp).substring(5, 7);
      setTimeout(function () {
        _this2.index = 1;
        container.style.transform = 'translateX(' + -_this2.index * _this2.liW + 'px)';
        container.style.webkitTransform = 'translateX(' + -_this2.index * _this2.liW + 'px)';
        container.style.transition = 'all 0s';
        container.style.webkitTransition = 'all 0s'; //兼容
      }, 200);
    }
  },
  mounted: function mounted() {
    this.liW = this.$refs.containerLi.clientWidth;
  }
};