/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Checker from "vux/src/components/checker/checker.vue";
import CheckerItem from "vux/src/components/checker/checker-item.vue";
import WeekCalendar from '@/components/WeekCalendar/WeekCalendar';
import Scroll from '@/components/Scroll/Scroll';
import Group from 'vux/src/components/group';
export default {
  name: "Attendances",
  components: {
    Checker: Checker,
    CheckerItem: CheckerItem,
    WeekCalendar: WeekCalendar,
    Scroll: Scroll,
    Group: Group
  },
  data: function data() {
    return {
      isFirst: true,
      depList: [{
        gateBh: 0,
        doorName: ""
      }],
      gateBh: 0,
      list: [],
      dayList: [],
      currDate: "",
      startDate: "",
      userSerial: "",
      showFlag: true,
      todayDate: {},
      selectMonth: '',
      selectDay: '',
      popFlag: false,
      popStyle: {
        top: '0px',
        right: '0px'
      },
      pullDownRefresh: {
        threshold: 60,
        // 下拉多少开始刷新
        stop: 40 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      tabIndex: 0
    };
  },
  methods: {
    // 显示信息
    showInfo: function showInfo(xh, userLx) {
      this.$router.push({
        path: '/TempDetail',
        query: {
          xh: xh,
          userLx: userLx,
          bk: true
        }
      });
    },
    // 选择场所时
    onSelDep: function onSelDep() {
      this.list = [];

      if (this.startDate) {
        this.pageIndex = 1;
        this.getTempRecord();
      }
    },
    popShow: function popShow() {
      this.popFlag = false;
    },
    dayClick: function dayClick(date) {
      this.showFlag = true;
      this.list = [];
      this.startDate = date;
      this.selectMonth = this.$utils.DateUtils.getMonth(date);
      this.selectDay = date;
      this.pageIndex = 1;
      this.getTempRecord();
    },
    monthClick: function monthClick() {},
    changTitle: function changTitle() {
      this.showFlag = !this.showFlag;
    },
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.list = [];
      this.pageIndex = 1;
      this.getTempRecord();
    },
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getTempRecord();
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    // 获取温度记录列表
    getTempRecord: function getTempRecord() {
      var _this = this;

      // alert(this.selectDay + "," + this.gateBh)
      this.isFirst = false;
      this.emptyShow = false;
      this.$utils.Tools.loadShow();
      this.$api.BodyTemp.getTempRecord({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.userSerial,
          ctDate: this.selectDay.split(" ")[0],
          gateBh: this.gateBh
        }
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.updateScroll(false);
        } else {
          var _this$list;

          (_this$list = _this.list).push.apply(_this$list, _toConsumableArray(data.records));

          _this.pageIndex = data.pageIndex;
          _this.totalPage = data.totalPage;

          if (_this.list.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll(false);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getDepList: function getDepList() {
      var _this2 = this;

      this.$api.BodyTemp.getDepList({
        userSerial: this.userSerial
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.depList = data || []; // 默认选择第一个

          if (_this2.depList.length) {
            _this2.gateBh = _this2.depList[0].gateBh;
          } // 获取日期列表


          _this2.getSysDate();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getSysDate: function getSysDate() {
      var _this3 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.currDate = data.sysDate;
          _this3.startDate = data.sysDate;
          _this3.selectMonth = _this3.$utils.DateUtils.getMonth(_this3.startDate);
          _this3.selectDay = _this3.$utils.DateUtils.getFullDate(_this3.startDate); // 获取记录列表

          _this3.dayClick(_this3.startDate);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: "title",
        url: ""
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem("userSerial");
  },
  mounted: function mounted() {
    this.isFirst = true; // 获取部门列表

    this.getDepList();

    if (this.$route.params.notification) {
      this.pushHistory();
    } // this.pushHistory();
    // window.onpopstate = () => {
    //     this.$router.push('/');
    // }

  },
  activated: function activated() {
    if (!this.isFirst) {
      this.onPullingDown();
    }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem("attendDate");
  }
};